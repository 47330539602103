const nonCorporateClients = [
  "DC",
  "AZ",
  "HH",
  "ZH",
  "TP",
  "CC",
  "AB",
  "AW",
  "MB",
];
export const checkCorporate = (clientCode) => {
  console.log("aisisisisis", clientCode);
  let clientIndex = nonCorporateClients.findIndex((e) => e === clientCode);
  if (clientIndex > -1) {
    return false;
  } else {
    return true;
  }
};
