import React from "react";
import { Row, Col } from "react-flexbox-grid";
import Button from "react-md/lib/Buttons/Button";
import Divider from "react-md/lib/Dividers";
import Moment from "react-moment";
import moment from "moment";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Helper from "util/Helper";
import EventService from "service/event/EventService";
import CallService from "../services/CallService";
import CallNoteService from "../services/CallNoteService";
import "../styles/call-notes.css";
import { SelectField } from "react-md";
import noNotes from "assets/icons/no_notes.png";
import SendDocuments from "../../../../sendDocument";
import ClevertapReact from "clevertap-react";
import EnvironmentService from "service/environment/EnvironmentService";
import { checkCorporate } from "../../../../../../../../../constants/CorporateClients";

const feedbackTemplate = {
  "Dr call": {
    label: "Dr call",
    templateId: "1107167221978187144",
    smsBody:
      "Hi {0}, How did you like the call with our doctor at Zyla. Your feedback will help improve our services {1}",
    link: "https://api.simplesat.io/api/rating/Bb6ts1WUFJ2E1UCC-QWgVLZ6dBlOwbUeaYwEiMoj5P8/?source=generic_embed",
  },
  HRA: {
    label: "HRA",
    templateId: "1107167221947992493",
    smsBody:
      "Hi {0}, How did you like the call with our nutritionist at Zyla. Your feedback will help improve our services {1}",
    link: "https://api.simplesat.io/api/rating/aSGvOfBcBMI4LENdUUlsFLs3Ywh7GmTamqHjiZbouac/?source=generic_embed",
  },
};
const feedbackTemplateAW = {
  HRA: {
    label: "HRA",
    templateId: "1107167221947992493",
    smsBody:
      "Hi {0}, How did you like the call with our nutritionist at Zyla. Your feedback will help improve our services {1}",
    link: "https://api.simplesat.io/api/rating/c7WEJHgvaKG5-RUZ2Y1qFSF-AHeUztXjWWV_Ce2Ce4E/?source=generic_embed",
  },
};

const unsuccessfullList = [
  {
    label: "Physio",
    value: "{0}, ",
  },
  {
    label: "Dr call",
    value: "{0}, follow the prescription as advised",
  },
  {
    label: "PRC",
    value:
      "{0}, hope the call went well and all your health queries were covered during the call. We have put together guidelines suggested by your care team during the call, please follow as suggested and do let us know in case of any queries ",
  },

  {
    label: "HRA",
    value:
      "{0}, hope the call went well and all your health issues were covered during the call. You will receive the health plan within 24 hours. Do let us know in case of any queries ",
  },
  {
    label: "Report analysis",
    value:
      "{0}, your nutritionist has shared below guidelines as per your latest reports. Please follow them along with the health plan. Do let us know in case of any queries  ",
  },
];

class CallNotes extends React.Component {
  constructor(props) {
    super(props);
    this.helper = new Helper();

    this.callService = new CallService();
    this.callNoteService = new CallNoteService();
    this.envirnomnetService = new EnvironmentService();

    this.list1 = [
      {
        label: "Not reachable/ Switched off",
        value:
          "{0}, Zyla team tried reaching you for the scheduled call at {1} but unfortunately your number was unreachable. Please let us know if you would like to reschedule this call.",
      },

      {
        label: "No answer",
        value:
          " {0}, Zyla team tried reaching you for the scheduled call at {1} but unfortunately it was not answered. Please let us know if you would like to reschedule this call.",
      },

      {
        label: "Disconnected",
        value:
          "{0}, Zyla team tried reaching you for the scheduled call at {1} but unfortunately the call was disconnected. Please let us know if you would like to reschedule this call.",
      },

      {
        label: "Asked for reschedule",
        value:
          "{0}, Zyla team tried reaching you for the scheduled call at {1} but we understand you requested for reschedule. Please let us know for when you would like to reschedule this call.",
      },

      {
        label: "Care team not available",
        value:
          "{0}, we are sorry to inform you that due to unavoidable circumstances, we have to reschedule your call originally for {1}. Please let us know for when we can reschedule this call.",
      },
    ];
    let editorState;
    if (props.call.plan) {
      const contentBlock = htmlToDraft(props.call.plan);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);
      }
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      selectedFeedback: {},
      sendDialog: false,
      emailMessage: "",
      selectedReason: "",
      selectedValue: "",
      checkEditor: false,
      selectedName: "",
      patient: "",
      check1: false,
      check: false,
      patientId: props.patientId || null,
      callId: props.callId || "",
      call: props.call || {},
      callNotes: props.callNotes || [],
      doctorPhoneNumber: props.doctorPhoneNumber || "",
      callerName: props.callerName || "",
      callNote: {
        callId: props.callId || "",
        content: "",
      },
      error: {
        content: "",
      },
      editorState: editorState,
      editorStateNote: EditorState.createEmpty(),
      doctorId: props.doctorId || null,
      patientId: props.patientId || null,
      lastName: props.patient.lastName || "",
      gender: props.patient.gender || "",
      patient: props.patient,
      planList: props.planList || [],
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      patient: props.patient,
      lastName: props.patient.lastName,
      gender: props.patient.gender,
    });

    if (props.callId !== this.state.callId) {
      let editorState;
      if (props.call.plan) {
        const contentBlock = htmlToDraft(props.call.plan);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          editorState = EditorState.createWithContent(contentState);
        }
      } else {
        editorState = EditorState.createEmpty();
      }

      this.setState({
        patientId: props.patientId,
        callId: props.callId || "",
        call: props.call || {},
        callNotes: props.callNotes || [],
        doctorPhoneNumber: props.doctorPhoneNumber || "",
        callerName: props.callerName || "",
        editorState: editorState,
        editorStateNote: EditorState.createEmpty(),
      });
    }
  }

  createMsges(listMessage, date, gender, name) {
    var salutation = "";
    if (gender == 1) salutation = "Dear Ms." + name;
    else salutation = "Dear Mr." + name;
    return listMessage.format(
      salutation,
      moment(new Date(date)).format("hh:mm A DD MMM YYYY ")
    );
  }

  render() {
    const { sendDialog, patient, emailMessage, selectedName, planList } =
      this.state;

    const callNotes = this.state.callNotes.map(
      ({ id, content, updatedAt, disabled }, index) => (
        <div key={id} className="item-call-note">
          <p>
            <Moment format="DD MMM YYYY hh:mm a">{updatedAt}</Moment>
          </p>
          <p dangerouslySetInnerHTML={this.helper.createMarkup(content)} />
          <Button
            className="btn-action-email-call-note"
            raised
            primary
            label="Send to Patient's App"
            disabled={disabled}
            onClick={() =>
              this._directMessageToPatient(this.state.patientId, id, index)
            }
          >
            chat_bubble
          </Button>
          <br />
          <Button
            className="btn-action-email-call-note"
            raised
            secondary
            label="Send to Patient's Email"
            onClick={() => {
              this.setState({ sendDialog: true, emailMessage: content });
            }}
          >
            email
          </Button>

          {index + 1 !== this.state.callNotes.length && <Divider />}
        </div>
      )
    );

    return (
      <div>
        <Row start="xs">
          <Col xs={12}>
            <div className="md-paper md-paper--1 container-call-plan">
              {this.state.call.doctorId === this.state.doctorId && (
                <div>
                  <h2>
                    {"Call Plan" +
                      (this.state.callerName
                        ? " for " + this.state.callerName
                        : "")}
                    <Button
                      icon
                      tooltipLabel="Edit"
                      onClick={() => this._onClickEditCall()}
                    >
                      edit
                    </Button>
                  </h2>
                  <hr />
                  {!this.state.editMode && (
                    <div>
                      {this.state.call.plan && (
                        <p
                          dangerouslySetInnerHTML={this.helper.createMarkup(
                            this.state.call.plan
                          )}
                        />
                      )}
                      {!this.state.call.plan && <p>No Call Plan</p>}
                    </div>
                  )}

                  {this.state.editMode && (
                    <div>
                      <Editor
                        spellCheck={true}
                        editorState={this.state.editorState}
                        wrapperClassName=""
                        editorClassName=""
                        hashtag={{
                          separator: " ",
                          trigger: "#",
                        }}
                        onEditorStateChange={this._onPlanEditorStateChange}
                      />
                      <Button
                        className="btn-action-call"
                        raised
                        primary
                        label="Save"
                        onClick={() => this._onClickSaveCall()}
                      >
                        save
                      </Button>
                      <Button
                        className="btn-action-call"
                        raised
                        secondary
                        label="Cancel"
                        onClick={() => this._onClickCancelCall()}
                      >
                        cancel
                      </Button>
                      <hr />
                    </div>
                  )}
                </div>
              )}
              <h2>
                {"Call Notes" +
                  (this.state.callerName ? " by " + this.state.callerName : "")}
              </h2>
              <hr />
              {callNotes}
              {this.state.callNotes.length > 0 && <hr />}
              {this.state.callNotes.length === 0 && (
                <Row center="xs">
                  <Col xs={12}>
                    <img src={noNotes} width="60%" />
                    <h2>No Notes</h2>
                  </Col>
                </Row>
              )}
              {!this.state.createMode &&
                this.state.call.doctorId === this.state.doctorId && (
                  <Button
                    className="btn-action-call"
                    raised
                    primary
                    label="Add Call Notes"
                    onClick={() => this._onClickAddCallNote()}
                  >
                    add
                  </Button>
                )}

              {this.state.createMode && (
                <div>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Button
                        className={
                          selectedName === "successfull"
                            ? "selected-callnote-btn"
                            : "unselected-callnote-btn"
                        }
                        raised
                        onClick={() => {
                          this._resetCallNote();
                          this.setState({
                            check1: true,
                            check: false,
                            selectedName: "successfull",
                            selectedReason: { label: "Successful" },
                          });
                        }}
                      >
                        SuccessFull
                      </Button>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Button
                        className={
                          selectedName === "unsuccessfull"
                            ? "selected-callnote-btn"
                            : "unselected-callnote-btn"
                        }
                        raised
                        onClick={() => {
                          this._resetCallNote();
                          this.setState({
                            check1: true,
                            check: false,
                            selectedName: "unsuccessfull",
                          });
                        }}
                      >
                        Unsuccessfull
                      </Button>
                    </Col>
                  </Row>
                  <br />

                  {this.state.check && !this.state.check1 && (
                    <div>
                      <Editor
                        spellCheck={true}
                        editorState={this.state.editorStateNote}
                        wrapperClassName=""
                        editorClassName=""
                        hashtag={{
                          separator: " ",
                          trigger: "#",
                        }}
                        onEditorStateChange={this._onEditorStateChange}
                      />
                      <Button
                        className="btn-action-call"
                        raised
                        primary
                        label="Save"
                        onClick={() => this._onClickSaveCallNote()}
                      >
                        save
                      </Button>
                    </div>
                  )}

                  {this.state.check1 && !this.state.check && (
                    <div>
                      <div>
                        <br />

                        {selectedName === "unsuccessfull" && (
                          <SelectField
                            placeholder="Select Reason"
                            name="call-plan"
                            id="reason-list"
                            className="reason-list success-unsuccess-dropdown"
                            onChange={(value, index, a, b) => {
                              this.setState({
                                selectedReason: this.list1[index],
                              });
                              this._onSelect(value, index);
                            }}
                            lineDirection="center"
                            menuItems={this.list1}
                          />
                        )}
                        {selectedName === "successfull" && (
                          <SelectField
                            placeholder="Select Reason"
                            name="call-plan"
                            id="reason-list"
                            className="reason-list success-unsuccess-dropdown"
                            onChange={(value, index, a, b) => {
                              const { patient } = this.state;
                              let selectedItem =
                                feedbackTemplate[
                                  unsuccessfullList[index].label
                                ];
                              if (patient.clientCode === "AW") {
                                selectedItem =
                                  feedbackTemplateAW[
                                    unsuccessfullList[index].label
                                  ];
                              }

                              this.setState({
                                selectedReason: unsuccessfullList[index],
                                selectedFeedback: selectedItem,
                              });
                              this._onSelect(value, index);
                            }}
                            lineDirection="center"
                            menuItems={unsuccessfullList}
                          />
                        )}
                      </div>
                      <br />
                      {this.state.checkEditor && (
                        <div>
                          <Editor
                            spellCheck={true}
                            editorState={this.state.editorStateNote}
                            wrapperClassName=""
                            editorClassName=""
                            hashtag={{
                              separator: " ",
                              trigger: "#",
                            }}
                            onEditorStateChange={this._onEditorStateChange}
                          />
                          <Button
                            className="btn-action-call"
                            raised
                            primary
                            label="Save"
                            onClick={() => {
                              this._onClickSaveCallNote(); /*this._saveNotes()*/
                            }}
                          >
                            save
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
        {sendDialog && (
          <SendDocuments
            sendDialog={sendDialog}
            closeSendDocument={this.closeSendDocument}
            patient={patient}
            document={{ publicUrl: "", fileName: "" }}
            container={"email"}
            subTemplate={"callNote"}
            emailMessage={emailMessage}
          />
        )}
      </div>
    );
  }
  closeSendDocument = () => {
    this.setState({ sendDialog: false });
  };
  _onSelect = (data) => {
    this.setState({ checkEditor: true });
    const { lastName, gender } = this.state;
    var message = this.createMsges(
      data,
      this.props.call.start_time,
      gender,
      lastName
    );

    this.setState({ selectedValue: message });
    let temp;
    if (message) {
      const contentBlock = htmlToDraft(message);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );

        temp = EditorState.createWithContent(contentState);

        let callNote = this.state.callNote;

        callNote.content = message;

        this.setState({
          callNote,
        });
      }
    } else {
      temp = EditorState.createEmpty();
    }
    this.setState({ editorStateNote: temp });
  };
  _onEditorStateChange = (editorStateNote) => {
    let callNote = this.state.callNote;

    let content = draftToHtml(
      convertToRaw(editorStateNote.getCurrentContent())
    );
    callNote.content = content;

    this.setState({
      editorStateNote,
      callNote,
    });
  };

  _onPlanEditorStateChange = (editorState) => {
    let call = this.state.call;

    let plan = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    call.plan = plan;

    this.setState({
      editorState,
      call,
    });
  };

  _onClickEditCall = () => {
    this.setState({
      editMode: true,
    });
  };

  _onClickSaveCall = () => {
    this._updateCall();
  };

  _onClickCancelCall = () => {
    this.setState({
      editMode: false,
    });
  };

  _onClickAddCallNote = () => {
    this.setState({
      createMode: true,
    });
  };

  _directMessageToPatient = (userId, callNoteId, index) => {
    let onResponse = () => {
      this.helper.stopLoading();
      this.disableCallNoteButton(callNoteId, index);
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });
      this.helper.stopLoading();
    };

    let callNoteAndState = {};
    callNoteAndState.message = callNoteId + "";
    callNoteAndState.action = "call_note";
    this.callNoteService.directMessageToPatient(
      userId,
      callNoteAndState,
      onResponse,
      onError
    );
  };

  disableCallNoteButton = (callNoteId, index) => {
    this.helper.startLoading();
    let callNotes = this.state.callNotes;
    callNotes[index].disabled = true;
    this.setState({
      callNotes,
    });

    let onResponse = () => {
      this.helper.stopLoading();
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });
      let callNotes = this.state.callNotes;
      callNotes[index].disabled = false;
      this.setState(
        {
          callNotes,
        },
        () => {
          this.helper.stopLoading();
        }
      );
    };

    this.callNoteService.disableCallNoteButton(
      callNoteId,
      {},
      onResponse,
      onError
    );
  };

  // _getUserIdByPatientId = (patientId, callNoteId, index) => {
  //   this.helper.startLoading();

  // let onResponse = (data) => {
  //   this._directMessageToPatient(data.userId, callNoteId, index);
  // };

  // let onError = (error) => {
  //   EventService.emit("showError", { message: error.message });
  //   this.helper.stopLoading();
  // };

  //   this.callNoteService.getUserIdOfPatient(patientId, onResponse, onError);
  // };

  _onClickSaveCallNote = () => {
    this._handleSubmit();
  };

  _resetCallNote = () => {
    let editorStateNote = EditorState.createEmpty();

    this.setState({
      editorStateNote: editorStateNote,
    });
  };

  _handleSubmit = () => {
    const { selectedReason, patient } = this.state;
    console.log("selected reason", selectedReason);
    if (selectedReason && this.envirnomnetService.isProduction()) {
      this.cleverTapEvent();
      this.fetchDetails();
      this.whatsappEvent();
      if (checkCorporate(patient.clientCode) || patient.clientCode === "AW")
        this.sendFeedback();
    }

    if (this.state.callNote.content) {
      this._addCallNote();
    } else {
      EventService.emit("showError", { message: "Enter a valid content!" });
    }
  };
  cleverTapEvent = () => {
    const { selectedReason, patientId, patient, call, planList } = this.state;
    let payload = {
      Site: {
        identity: patientId.toString(),
      },
    };
    let plan = planList && planList.length > 0 ? planList[0].plan : null;
    let programEndDate =
      planList && planList.length > 0
        ? this.helper.getDate(planList[0].programEndDate)
        : null;

    let programStartDate =
      planList && planList.length > 0
        ? this.helper.getDate(planList[0].programStartDate)
        : null;
    // console.log("ff", payload);
    ClevertapReact.profile(payload);
    ClevertapReact.event("callNotes", {
      patientId: patientId,
      clientCode: patient.clientCode,
      email: patient.email,
      status: patient.status,
      phoneNo: patient.phoneno,
      reason: "BRIDGE_CN_" + selectedReason.label,
      startTime: this.helper.epoch(new Date(call.start_time)),
      planId: plan ? plan.id : null,
      programEndDate: this.helper.epoch(new Date(programEndDate)),
      programStartDate: this.helper.epoch(new Date(programStartDate)),
    });
  };
  sendFeedback = () => {
    const { selectedName, selectedFeedback } = this.state;
    // console.log("send feedback", selectedName, selectedFeedback);
    if (
      selectedName === "successfull" &&
      selectedFeedback !== undefined &&
      selectedFeedback.label !== undefined &&
      (selectedFeedback.label === "Dr call" || selectedFeedback.label === "HRA")
    ) {
      this.shortUrlForNps();
    }
  };
  shortUrlForNps = () => {
    const { patient, selectedFeedback } = this.state;
    let hp_feedback =
      selectedFeedback.link +
      "&customer_email=" +
      patient.email +
      "&customer_first_name=" +
      patient.firstName +
      "&company_name=" +
      patient.phoneno;

    let requestBody = {
      originalURL: hp_feedback,
    };
    // console.log("long link", hp_feedback);
    let onResponse = (response) => {
      this.sendFeedbackSMS(response.secureShortURL);
    };
    let onError = (err) => {};

    this.callNoteService.whatsappShort(requestBody, onResponse, onError);
  };
  sendFeedbackSMS = (hp_feedback) => {
    const { patient, selectedFeedback } = this.state;

    let smsBody = selectedFeedback.smsBody.format(
      patient.firstName.trim(),
      hp_feedback
    );

    let requestBody = {
      body: smsBody,
      entityid: "1301158167847151139",
      phoneNo: patient.phoneno.toString(),
      senderId: "ZYLAHE",
      templateid: selectedFeedback.templateId,
    };
    // console.log("sms bidy", requestBody);
    var onResponse = () => {
      this.audit();
      this.helper.stopLoading();
    };
    var onError = () => {
      this.helper.stopLoading();
    };
    this.callNoteService.sendSms(requestBody, onResponse, onError);
  };

  audit = () => {
    const { patient } = this.state;
    let requestBody = {
      patientId: patient.id,
      email: patient.email,
      phone: patient.phoneno,
      type: "callNote",
      name: "",
      link: "",
      createdAt: new Date().toISOString(),
      status: "sent",
      channel: "sms",
    };
    let onResponse = () => {};
    let onError = (error) => {};

    this.callNoteService.audit(requestBody, onResponse, onError);
  };
  whatsappEvent = () => {
    this.helper.startLoading();
    const { patientId, patient, selectedReason, call } = this.state;
    let requestBody = {
      userId: patientId.toString(),
      phoneNumber: patient.phoneno.toString(),
      countryCode: patient.countryCode.toString(),
      event: "BRIDGE_CN_" + selectedReason.label,
      traits: {
        startTime: moment(new Date(call.start_time).getTime()).format(
          "hh:mm a"
        ),
        startDay: moment(new Date(call.start_time).getTime()).format(
          "DD MMM YYYY"
        ),
        // programEndDate: new Date(programEndDate),
        // programStartDate: new Date(programStartDate),
      },
    };

    var onResponse = () => {
      this.helper.stopLoading();
    };
    var onError = () => {
      this.helper.stopLoading();
    };
    this.callService.whatsappEvent(requestBody, onResponse, onError);
  };
  fetchDetails = () => {
    const { patient, selectedName } = this.state;

    var onResponse = (res) => {
      if (res) {
        let patientDetails = res;
        this.createDisposition(patientDetails, true);
        if (selectedName === "unsuccessfull" && res.startsAt) {
          this.sendEmail(res);
        }
      } else {
        this.createDisposition(patient, false);
      }
    };
    var onError = (error) => {
      this.createDisposition(patient, false);
    };
    this.callService.fetchDetails(patient.phoneno, onResponse, onError);
  };

  createDisposition = (patientHa, haFlag) => {
    const { selectedReason, selectedName, patient } = this.state;
    var onResponse = (res) => {};
    var onError = (error) => {};
    this.callService.createDisposition(
      patient.phoneno,
      selectedName,
      selectedReason,
      patientHa,
      haFlag,
      onResponse,
      onError
    );
  };

  _updateCall = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this.setState({
        editMode: false,
        call: data,
      });

      // stop loading
      this.helper.stopLoading();

      EventService.emit("onAddCall", { createMode: false });
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });

      // stop loading
      this.helper.stopLoading();
    };

    // pre-request parsing
    let callRequest = {};
    const {
      id,
      doctorId,
      patientId,
      callee,
      plan,
      is_caregiver,
      due_date,
      start_time,
    } = this.state.call;
    callRequest.id = id;
    callRequest.doctorId = doctorId;
    callRequest.patientId = patientId;
    callRequest.plan = plan;
    callRequest.is_caregiver = is_caregiver;
    callRequest.due_date = new Date(due_date).getTime();
    callRequest.start_time = new Date(start_time).getTime();
    callRequest.end_time = null;

    this.callService.update(id, callRequest, onResponse, onError);
  };

  _addCallNote = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      let callNotes = this.state.callNotes;
      callNotes.unshift(data);
      this.setState({
        callNotes,
        createMode: false,
      });

      this.helper.stopLoading();
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });
      // stop loading
      this.helper.stopLoading();
    };

    // pre-request parsing
    let callNoteRequest = {};
    callNoteRequest.call_id = this.state.callId;
    callNoteRequest.content = this.state.callNote.content;

    this.callNoteService.new(callNoteRequest, onResponse, onError);
  };
  //

  sendEmail = (response) => {
    const { patient } = this.state;
    let requestBody = {
      sysid: "cepapi0063",
      emailid: patient.email,
      msgid: "252151123",
      attributes: {
        "user.FirstName": patient.firstName,
        "user.CustomAttribute.bookingStart": response.startsAt,
        "user.CustomAttribute.bookingDuration": "",
        "user.CustomAttribute.bookingReference": "",
        "user.CustomAttribute.cancelLink": response.cancelLink,
        "user.CustomAttribute.rescheduleLink": response.rescheduleLink,
        "user.CustomAttribute.bookingPhone": "",
      },
    };
    let onResponse = () => {
      // auditEmail();
    };
    let onError = (error) => {
      // setDisable(false);
    };

    this.callNoteService.sendEmail(requestBody, onResponse, onError);
  };
}

export default CallNotes;
