import AuthenticationService from "../auth/AuthenticationService";
import APIConfig from "./APIConfig";
import SessionManager from "../auth/SessionManager";
import Helper from "util/Helper";

class newApiService {
  constructor() {
    this.KEY_CLIENT = "client";
    this.KEY_PHONENO = "phone_no";
    this.KEY_TOKEN = "auth_token";

    this.CLIENT = "bridge_web";
    this.DOCTOR_CLIENT = "doctor_web";
    this.USER_PROFILE = "zy.USER_PROFILE";

    this.helper = new Helper();

    this.initializeHeaders();
  }

  getUserProfile() {
    return JSON.parse(localStorage.getItem(this.USER_PROFILE));
  }
  isLoggedIn() {
    let user = this.getUserProfile();
    let sessionToken = JSON.parse(localStorage.getItem(this.USER_PROFILE))
      ?.accessToken?.accessToken;

    if (sessionToken) {
      return true;
    }

    return false;
  }
  getSessionToken() {
    return JSON.parse(localStorage.getItem(this.USER_PROFILE))?.accessToken
      ?.accessToken;
  }

  async saveNewAccessToken() {
    let url = APIConfig.user.refreshToken;
    let body = JSON.parse(localStorage.getItem(this.USER_PROFILE))?.accessToken;
    body.accessToken = "";
    let response = (res) => {
      let user = JSON.parse(localStorage.getItem(this.USER_PROFILE));
      user.accessToken = res;
      localStorage.setItem(this.USER_PROFILE, JSON.stringify(user));
      this.session = new SessionManager();
      this.session.setCookie(this.KEY_TOKEN, res?.accessToken, 1051200); // 2 years
    };
    let error = (err) => {
      this.doLogOut();
    };
    await this.postv3(url, body, response, error);
    return;
  }
  async saveNewDocToken() {
    let url = APIConfig.documentApis.tokenGenerate;
    let response = (res) => {
      localStorage.setItem("zy.bridge.doc.token", JSON.stringify(res));
    };
    let error = (err) => {
      console.log("Error in getting new doc token: ", err);
    };
    await this.getv3(url, response, error);
    return;
  }
  doLogOut() {
    this.authenticationService = new AuthenticationService();
    this.authenticationService.logout();
    return;
  }
  setNewToken() {
    const newTokenPromise = new Promise((resolve, reject) => {
      this.saveNewAccessToken()
        .then(() => {
          this.initializeHeaders();
          this.saveNewDocToken()
            .then(() => {
              resolve();
            })
            .catch(() => {
              resolve();
            });
        })
        .catch(() => {
          this.doLogOut();
        });
    });

    return newTokenPromise;
  }

  initializeHeaders() {
    let user = this.getUserProfile();
    let phoneNo = this.isLoggedIn() ? user.phoneno : "";
    let token = this.isLoggedIn() ? this.getSessionToken() : "";
    let isDoctor = false;
    if (user && user.employeeProfile && user.employeeProfile.id > 0) {
      isDoctor = false;
    } else if (
      user &&
      user.employeeProfile &&
      user.employeeProfile.id > 0 &&
      user.doctorProfile &&
      user.doctorProfile.id > 0
    ) {
      isDoctor = false;
    } else if (user && user.doctorProfile && user.doctorProfile.id > 0) {
      isDoctor = true;
    } else {
      isDoctor = false;
    }

    this.headers = {
      client: isDoctor ? this.DOCTOR_CLIENT : this.CLIENT,
      "Content-Type": "application/json",
    };

    if (phoneNo !== "") {
      this.headers.phone_no = phoneNo;
    }

    if (token !== "") {
      this.headers.auth_token = token;
    }
  }

  get(url, onResponse, onError1) {
    var onError = (err) => {
      var onResponse1 = (res) => {
        onResponse(res);
      };
      var onError2 = (err) => {
        onError1(err);
      };
      this.getAgain(url, onResponse1, onError2);
    };
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.getAgain(url, onResponse, onError1);
          });
          return;
        } else if (response.status === 204) {
          onResponse(undefined);
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response

        response
          .json()
          .then(function (data) {
            onResponse(data);
            return;
          })
          .catch((err) => {});
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  getAgain(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getImage(url, onResponse, onError) {
    let that = this;

    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.getImageAgain(url, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // return the buffer
        response.arrayBuffer().then((buffer) => {
          onResponse(buffer);
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  getImageAgain(url, onResponse, onError) {
    let that = this;

    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // return the buffer
        response.arrayBuffer().then((buffer) => {
          onResponse(buffer);
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  post(url, params, onResponse, onError1) {
    var onError = (err) => {
      var onResponse1 = (res) => {
        onResponse(res);
      };
      var onError = (err) => {
        onError1(err);
      };
      this.postAgain(url, params, onResponse1, onError);
    };

    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postAgain(url, params, onResponse, onError1);
          });
          return;
        } else if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postAgain(url, params, onResponse, onError) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postv2(url, params, onResponse, onError) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postv2Again(url, params, onResponse, onError);
          });
          return;
        } else if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postv2Again(url, params, onResponse, onError) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  postWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postWithFormDataAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  postWithFormDataAgain(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patchtWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PATCH",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.patchtWithFormDataAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  patchtWithFormDataAgain(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PATCH",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  putWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.putWithFormDataAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message:
              error && error.message !== undefined && error.message
                ? error.message
                : "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  putWithFormDataAgain(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message:
              error && error.message !== undefined && error.message
                ? error.message
                : "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  put(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.putAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message:
                  error && error.message !== undefined && error.message
                    ? error.message
                    : "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message:
              error && error.message !== undefined && error.message
                ? error.message
                : "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  putAgain(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message:
                  error && error.message !== undefined && error.message
                    ? error.message
                    : "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message:
              error && error.message !== undefined && error.message
                ? error.message
                : "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patch(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.patchAgain(url, params, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  patchAgain(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  delete(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then((response) => {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.deleteAgain(url, onResponse, onError);
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Goal deleted.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch((error) => {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  deleteAgain(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then((response) => {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Goal deleted.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch((error) => {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  downloadCSV(url, fileName, onResponse, onError) {
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then((response) => response.text())
      .then((response) => {
        var csv = response;
        var url = "data:text/plain;charset=utf-8," + encodeURIComponent(csv);
        //  window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        onResponse({ success: "File Downloaded" });
      })
      .catch((error) => {
        if (this.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getWithOptionalHeaders(url, header, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.getWithOptionalHeadersAgain(url, header, onResponse, onError);
          });
          return;
        } else if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getWithOptionalHeadersAgain(url, header, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  putWithOptionalHeaders(url, params, header, onResponse, onError) {
    let that = this;
    this.headers["Authorization"] = header.Authorization;
    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.putWithOptionalHeadersAgain(
              url,
              params,
              header,
              onResponse,
              onError
            );
          });
          return;
        } else if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  putWithOptionalHeadersAgain(url, params, header, onResponse, onError) {
    let that = this;
    this.headers["Authorization"] = header.Authorization;
    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  downloadCSVwithOptionalHeader(url, fileName, header, onResponse, onError) {
    let that = this;
    this.headers["Authorization"] = header.Authorization;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then((response) => response.text())
      .then((response) => {
        var csv = response;
        var url = "data:text/plain;charset=utf-8," + encodeURIComponent(csv);
        //  window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        onResponse({ success: "File Downloaded" });
      })
      .catch((error) => {
        if (this.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postWithOptionalHeaders(url, params, headers, onResponse, onError1) {
    var onError = (err) => {
      var onResponse = (res) => {
        onResponse(res);
      };
      var onError = (err) => {
        onError1(err);
      };
      this.postAgain(url, params, onResponse, onError);
    };

    let that = this;

    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postAgain(url, params, onResponse, onError1);
          });
          return;
        } else if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postWithFormDataDownload(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status === 401) {
          that.setNewToken().then(() => {
            that.postWithFormDataDownloadAgain(
              url,
              params,
              onResponse,
              onError
            );
          });
          return;
        } else if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.blob().then((data) => {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postWithFormDataDownloadAgain(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.blob().then((data) => {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  //getv3 is used to get new document token
  getv3(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 204) {
          return onResponse(undefined);
        }
        if (response.status !== 200) {
          return response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        return response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  //postv3 used for getting new access token
  postv3(url, params, onResponse, onError) {
    let that = this;
    let newHeader = this.headers;
    delete newHeader[this.KEY_TOKEN];

    return fetch(url, {
      method: "POST",
      headers: newHeader,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          return response.json().then(function (data) {
            onResponse(data);
          });
        }
        if (response.status !== 200) {
          return response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
        }

        // Examine the text in the response
        return response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
}

export default new newApiService();
