import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

let environmentService = new EnvironmentService();
let serviceAPI = environmentService.getServiceAPI();
let header = {
  Authorization: "api-labtest-request-service",
  client: "service",
};
class NavigoAPIServices {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }
  //create appointment
  createAppointment = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.createAppointment;
    this.apiService.post(url, body, onResponse, onError);
  };

  uploadDocumentDigital(body, onResponse, onError) {
    let url = APIConfig.navigoDashboard.uploadDocumentDigital;
    return this.apiService.post(url, body, onResponse, onError);
  }
  uploadDocumentLabtestAutomation(body, onResponse, onError) {
    let url = APIConfig.navigoDashboard.uploadDocumentLabtestAutomation;
    return this.apiService.post(url, body, onResponse, onError);
  }
  //get patient data
  getPatientData = (searchStr, onResponse, onError) => {
    let url = APIConfig.patient.search.format(searchStr, 2, true);
    this.apiService.get(url, onResponse, onError);
  };
  //get data through patient ID
  getPatientDataThroughID = (searchStr, onResponse, onError) => {
    let url = APIConfig.patient.get.format(searchStr);
    this.apiService.get(url, onResponse, onError);
  };
  //get appointment by patient
  getAppointMentByPatient = (id, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.appointmentByPatient.format(id);
    this.apiService.get(url, onResponse, onError);
  };
  //get all appointments
  getAllAppointments = (
    limit,
    skip,
    onResponse,
    onError,
    status,

    filter = "",
    fromDate = "",
    toDate = "",
    serviceType = "",
    createdFrom = "",
    createdTo = ""
  ) => {
    let url = APIConfig.navigoDashboard.allAppointments.format(
      limit,
      skip,
      filter,
      fromDate,
      toDate,
      serviceType,
      createdFrom,
      createdTo
    );
    if (status !== 4) {
      url = APIConfig.navigoDashboard.getAllAppointmentByStatus.format(
        status,
        limit,
        skip,
        filter,
        fromDate,
        toDate,
        serviceType
      );
    }
    this.apiService.get(url, onResponse, onError);
  };
  //update appointment
  updateAppointment = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.createAppointment;
    this.apiService.put(url, body, onResponse, onError);
  };
  //get time slots
  getTimeSlots = (serviceId, email, date, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.getTimeSlots.format(
      serviceId,
      date,
      email
    );
    this.apiService.get(url, onResponse, onError);
  };
  //get All services
  getAllServices = (onResponse, onError) => {
    let url = APIConfig.navigoDashboard.getAllServices;
    this.apiService.get(url, onResponse, onError);
  };
  //get single service data
  getSingleServiceData = (id, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.getAllServices + "/" + `${id}`;
    this.apiService.get(url, onResponse, onError);
  };

  //get service by client code
  getServiceByClientCode = (clientCode, onResponse, onError) => {
    let url =
      APIConfig.navigoDashboard.getServiceByClientCode.format(clientCode);
    this.apiService.get(url, onResponse, onError);
  };

  //get service by patient id
  getServiceByPatientId = (patientId, onResponse, onError) => {
    let url =
      APIConfig.navigoDashboard.getServicesByPatientId.format(patientId);
    this.apiService.get(url, onResponse, onError);
  };

  //create a service
  createService = (isCustom, cc, id, body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.createService;
    let customBody = { ...body };
    if (isCustom) {
      url = APIConfig.navigoDashboard.createUpdateCustomizedService;
      customBody = { ...body, masterService: id, clientCode: cc };
    }

    this.apiService.post(url, customBody, onResponse, onError);
  };

  //update service
  updateService = (isCustom, cc, body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.createService;
    let customBody = { ...body };
    if (isCustom) {
      url = APIConfig.navigoDashboard.createUpdateCustomizedService;
      customBody = { ...body, masterService: body.ID, clientCode: cc };
    }
    this.apiService.put(url, body, onResponse, onError);
  };

  //get All client code
  getAllClientCode = (onResponse, onError) => {
    let url = APIConfig.whitelisting.getAll;
    this.apiService.get(url, onResponse, onError);
  };

  //get All calendars
  getAllCalendars = (onResponse, onError) => {
    let url = APIConfig.navigoDashboard.getAllCalendars;
    this.apiService.get(url, onResponse, onError);
  };

  //
  getCalendarAccessLink = () => {
    return APIConfig.navigoDashboard.accessCalendars;
  };

  //update stataus of appointment
  updateAppointmentStatus = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.updateAppointmentStatus;
    return this.apiService.post(url, body, onResponse, onError);
  };

  //reschedule appointments
  rescheduleAppointments = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.rescheduleAppointments;
    return this.apiService.post(url, body, onResponse, onError);
  };

  //upload  image
  uploadImg = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.uploadContent;
    return this.apiService.postWithFormData(url, body, onResponse, onError);
  };

  //upload patient report
  uploadDocument(fileUrl, fileName, document, onSuccess, onError, pId = null) {
    let documentRequest = new FormData();
    let documentJSON = {};
    // documentJSON.id = document.id;
    documentJSON.patientId = pId;

    documentJSON.title = fileName;
    documentJSON.description = document.description;
    documentJSON.type = document.type;
    documentJSON.format = document.format;
    documentJSON.status = document.status;

    documentRequest.append("file", fileUrl);
    documentRequest.append("fileName", fileName);
    documentRequest.append("document", JSON.stringify(documentJSON));
    documentRequest.append("patientId", pId);

    const url = APIConfig.documentApis.uploadDoc;
    this.apiService.postWithFormData(
      url,
      documentRequest,

      (response) => {
        onSuccess(response);
      },
      (error) => {
        onError(error);
      }
    );
  }

  //get user's address
  getPrimaryAddress = (id, onResponse, onError) => {
    let url = APIConfig.familyPlan.primaryAddress.format(id);
    return this.apiService.get(url, onResponse, onError);
  };

  //get time slots for redcliffe and thyrocare
  getLabtestTimeSlot = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.getTimeSlotForLabTest;
    return this.apiService.post(url, body, onResponse, onError);
  };
  //create labtest appointment
  createLabtestAppointment = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.bookingForLabtest;
    return this.apiService.post(url, body, onResponse, onError);
  };
  // cancel and reschedule lab appointments
  cancelAndRescheduleLabAppointments = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.bookingForLabtest;
    return this.apiService.put(url, body, onResponse, onError);
  };

  //get customised services
  getCustomizedServices = (id, cc, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.getCustomizedService.format(id, cc);
    return this.apiService.get(url, onResponse, onError);
  };
  //upload appointment csv
  uploadAppointCSV = (body, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.uploadAppointCSV;
    return this.apiService.postWithFormDataDownload(
      url,
      body,
      onResponse,
      onError
    );
  };
  //get all vendors
  getAllVendors = (onResponse, onError) => {
    let url = APIConfig.navigoDashboard.vendors.all;
    return this.apiService.get(url, onResponse, onError);
  };

  //get all packages
  getAllPackages = (onResponse, onError) => {
    let url = APIConfig.navigoDashboard.packages.all;
    return this.apiService.get(url, onResponse, onError);
  };

  //get vendor packages
  getAllPackagesVendor = (id, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.vendors.packageVendor + "/" + id;
    return this.apiService.get(url, onResponse, onError);
  };

  //update appointment package id
  updatePackageId = (data, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.updatePackage;
    return this.apiService.post(url, data, onResponse, onError);
  };

  getDocumentByAppointment = (id, onResponse, onError) => {
    let url = APIConfig.navigoDashboard.getDocumentByAppointment.format(id);
    return this.apiService.get(url, onResponse, onError);
  };
}

export default NavigoAPIServices;
