import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class TrackingService {
  constructor() {
    this.apiService = new APIService();
  }

  getDataTables(onResponse, onError) {
    let url = APIConfig.detailedAssessment.getDataTables;
    return this.apiService.get(url, onResponse, onError);
  }

  getSurgeries(patientId, onResponse, onError) {
    let url = APIConfig.tracking.surgery.get.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }
  getNextSurgeries(patientid, pagesize, pageno, onResponse, onError) {
    let url = APIConfig.tracking.surgery.getNext.format(
      patientid,
      pagesize,
      pageno
    );
    return this.apiService.get(url, onResponse, onError);
  }
  getNextChiefComplaints(patientid, pagesize, pageno, onResponse, onError) {
    let url = APIConfig.tracking.chiefComplaint.getNext.format(
      patientid,
      pagesize,
      pageno
    );

    return this.apiService.get(url, onResponse, onError);
  }

  getMedicine(patientId, onResponse, onError) {
    let url = APIConfig.tracking.medicine.get.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }

  getNextMedicine(patientId, pagesize, pageNo, onResponse, onError) {
    let url = APIConfig.tracking.medicine.getNext.format(
      patientId,
      pagesize,
      pageNo
    );
    return this.apiService.get(url, onResponse, onError);
  }

  getNextLabTest(patientId, pagesize, pageno, onResponse, onError) {
    let url = APIConfig.tracking.labtest.getNext.format(
      patientId,
      pagesize,
      pageno
    );
    return this.apiService.get(url, onResponse, onError);
  }

  getCountLabtest(patientid, onResponse, onError) {
    let url = APIConfig.tracking.labtest.get.format(patientid);

    return this.apiService.get(url, onResponse, onError);
  }

  getNextDiagnosis = (patientId, pagesize, pageno, onResponse, onError) => {
    let url = APIConfig.tracking.diagnosis.getNext.format(
      patientId,
      pagesize,
      pageno
    );
    return this.apiService.get(url, onResponse, onError);
  };
  getCountDiag(patientid, onResponse, onError) {
    let url = APIConfig.tracking.diagnosis.get.format(patientid);

    return this.apiService.get(url, onResponse, onError);
  }

  getCountAddictions(patientid, onResponse, onError) {
    let url = APIConfig.tracking.addictions.get.format(patientid);

    return this.apiService.get(url, onResponse, onError);
  }

  getNextAddictions = (patientId, pagesize, pageno, onResponse, onError) => {
    let url = APIConfig.tracking.addictions.getNext.format(
      patientId,
      pagesize,
      pageno
    );
    return this.apiService.get(url, onResponse, onError);
  };
  getLatestLabTest = (patientId, onResponse, onError) => {
    let url = APIConfig.tracking.labtest.getLatest.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  };
  vitalExtraction = (
    userName,
    userId,
    startTime,
    endTime,
    patientId,
    onResponse,
    onError
  ) => {
    let url = APIConfig.vitalExtraction.get.format(
      patientId,
      startTime,
      endTime,
      userId,
      userName
    );
    return this.apiService.get(url, onResponse, onError);
  };
  //fetch Vital from device
  fetchVitals = (patientId, deviceId, date, onResponse, onError) => {
    let url = APIConfig.device.fetchVitals.format(patientId, deviceId, date);
    return this.apiService.get(url, onResponse, onError);
  };
  //fetch devices
  getAllDevices = (patientId, onResponse, onError) => {
    let url = APIConfig.device.getAllDevices.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  };
  getAllSalts = (onResponse, onError) => {
    let url = APIConfig.medicineDashboard.salt;
    return this.apiService.get(url, onResponse, onError);
  };
  fetchPlan(id, onResponse, onError) {
    let url = APIConfig.planAssignment.fetchAllPlan.format(id);
    return this.apiService.get(url, onResponse, onError);
  ;}
  
  getDocumentId(pid, onResponse, onError) {
    let url = APIConfig.tracking.labtest.getDocumentId.format(pid);
    return this.apiService.get(url,onResponse, onError);
  }
}

export default TrackingService;
