import React, { useEffect, useRef, useState } from "react";
import {
  Select,
  TextField,
  MenuItem,
  TextareaAutosize,
  CircularProgress,
} from "@material-ui/core";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "react-md/lib/Buttons/Button";
import FontIcon from "react-md/lib/FontIcons";
import backIcon from "../../../../../assets/icons/black-back-icon.svg";
import editPencilIcon from "../../../../../assets/icons/pencil-edit-icon.svg";
import editPencilIconOrange from "../../../../../assets/icons/orange-edit-icon.svg";
import appointIcon from "../../../../../assets/icons/navigo-appoint-icon.svg";
import EventService from "../../../../../service/event/EventService";
import NavigoAPIServices from "../services/navigoAPIServices";
import { GetStringSlot } from "../index";
import noInsight from "../../../../../assets/images/no-insight.png";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import { useHistory, withRouter } from "react-router";

const navigoWeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const serviceTypeArr = [
  { name: "HRA", id: 1 },
  { name: "PRC", id: 2 },
  { name: "Physiotherapy consultation", id: 3 },
  { name: "Psychologist consultation", id: 4 },
  { name: "Doctor consultation", id: 5 },
  { name: "Pathology test", id: 6 },
  { name: "Radiology test", id: 7 },
  { name: "Other", id: 8 },
];
const NavigoTimeArr = [
  { id: 47, time: "00:00", timeNum: "" },
  { id: 46, time: "00:30", timeNum: "" },
  { id: 45, time: "01:00", timeNum: "" },
  { id: 44, time: "01:30", timeNum: "" },
  { id: 43, time: "02:00", timeNum: "" },
  { id: 42, time: "02:30", timeNum: "" },
  { id: 41, time: "03:00", timeNum: "" },
  { id: 40, time: "03:30", timeNum: "" },
  { id: 39, time: "04:00", timeNum: "" },
  { id: 38, time: "04:30", timeNum: "" },
  { id: 37, time: "05:00", timeNum: "" },
  { id: 36, time: "05:30", timeNum: "" },
  { id: 35, time: "06:00", timeNum: "" },
  { id: 34, time: "06:30", timeNum: "" },
  { id: 33, time: "07:00", timeNum: "" },
  { id: 32, time: "07:30", timeNum: "" },
  { id: 31, time: "08:00", timeNum: "" },
  { id: 30, time: "08:30", timeNum: "" },
  { id: 1, time: "09:00", timeNum: "" },
  { id: 2, time: "09:30", timeNum: "" },
  { id: 3, time: "10:00", timeNum: "" },
  { id: 4, time: "10:30", timeNum: "" },
  { id: 5, time: "11:00", timeNum: "" },
  { id: 6, time: "11:30", timeNum: "" },
  { id: 7, time: "12:00", timeNum: "" },
  { id: 8, time: "12:30", timeNum: "" },
  { id: 9, time: "13:00", timeNum: "" },
  { id: 10, time: "13:30", timeNum: "" },
  { id: 11, time: "14:00", timeNum: "" },
  { id: 12, time: "14:30", timeNum: "" },
  { id: 13, time: "15:00", timeNum: "" },
  { id: 14, time: "15:30", timeNum: "" },
  { id: 15, time: "16:00", timeNum: "" },
  { id: 16, time: "16:30", timeNum: "" },
  { id: 17, time: "17:00", timeNum: "" },
  { id: 18, time: "17:30", timeNum: "" },
  { id: 19, time: "18:00", timeNum: "" },
  { id: 20, time: "18:30", timeNum: "" },
  { id: 21, time: "19:00", timeNum: "" },
  { id: 22, time: "19:30", timeNum: "" },
  { id: 23, time: "20:00", timeNum: "" },
  { id: 24, time: "20:30", timeNum: "" },
  { id: 25, time: "21:00", timeNum: "" },
  { id: 26, time: "21:30", timeNum: "" },
  { id: 27, time: "22:00", timeNum: "" },
  { id: 28, time: "22:30", timeNum: "" },
  { id: 29, time: "23:00", timeNum: "" },
];
const editorHelperMethod = (desc) => {
  let mymethod = EditorState.createEmpty();
  const blocksFromHTML = convertFromHTML(desc);
  if (blocksFromHTML.contentBlocks) {
    const editState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    mymethod = EditorState.createWithContent(editState);
  }
  return mymethod;
};
function NavigoSingleService(props) {
  const [isView, setIsView] = useState(props.isView);

  const [services, setServices] = useState([]);
  const [layout, setLayout] = useState(1);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [selectedClientCode, setSelectedClientCode] = useState("");
  const [sname, setSName] = useState("");
  const [sDesc, setSDesc] = useState("");
  const [instructions, setInstructions] = useState(EditorState.createEmpty());
  const [familyMembersCount, setFamilyMembersCount] = useState("");
  const [sTimings, setSTimings] = useState([]);
  const [sUnit, setSUnit] = useState("");
  const [sDuration, setSDuration] = useState("");
  const [breakStart, setBreakStart] = useState("");
  const [breakEnd, setBreakEnd] = useState("");
  const [sCalendar, setSCalendar] = useState([]);
  const [sClients, setSClients] = useState([]);
  const [isSaving, setISSaving] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const [calendarOptions, setCalendaroptions] = useState([]);
  const [clientCodeList, setClientCodeList] = useState([]);
  const [finalClientCodeList, setFinalClientCodeList] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [forFamily, setForFamily] = useState(false);
  const [isUnlimitedService, setIsUnlimitedService] = useState(false);
  const [addReq, setAddressRequired] = useState(false);
  const [bookingNoticeMin, setBookingNoticeMin] = useState("");
  const [bookingNoticeMax, setBookingNoticeMax] = useState("");
  const [labtest, setLabtest] = useState(false);
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [isPost, setIsPost] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [packageId, setPackageId] = useState("");
  const globalTimer = useRef(null);
  const uploadFileRef = useRef(null);
  const history = useHistory();
  const navigoAPIServices = new NavigoAPIServices();

  useEffect(() => {
    if (props.cc && props.id) {
      handleGetServiceData(props.cc, props.id);
    } else {
      if (props.isNew) {
        handleAddNewService();
      } else {
        handleGetMasterService();
      }
    }

    handleGetAllCalendars();
    navigoAPIServices.getAllClientCode(
      (res) => {
        if (res) {
          setClientCodeList([...res]);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot retrieve client code",
        });
      }
    );

    return () => {
      if (globalTimer.current) {
        clearTimeout(globalTimer.current);
      }
    };
  }, []);

  const handleGetMasterService = (isPost = false) => {
    setSelectedServiceId(props.id);
    setIsPost(isPost);
    handleOpenService({ ...props.currentService });
  };

  const handleGetAllCalendars = () => {
    navigoAPIServices.getAllCalendars(
      (res) => {
        if (res && res.length > 0) {
          setCalendarList([...res]);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot retrieve client code",
        });
      }
    );
  };

  const handleGetServiceData = (cc, id) => {
    navigoAPIServices.getCustomizedServices(
      id,
      cc,
      (res) => {
        handleOpenService(res);
      },
      (err) => {
        if (props.isEdit) {
          handleGetMasterService(true);
        } else {
          setLayout(2);
        }
      }
    );
  };

  const handleCancel = () => {
    setSelectedServiceId(null);
    setLayout(1);
  };
  const handleSave = () => {
    let calendarArr = [];
    let availableDays = [];
    let clientArr = [];

    let myClientSet = new Set();
    if (sClients.length > 0) {
      sClients.forEach((clt) => {
        if (clt.clientCode != "" && !myClientSet.has(clt.clientCode)) {
          clientArr.push({
            clientCode: clt.clientCode,
            maxSlots: clt.maxSlots ? parseInt(clt.maxSlots) : 1,
          });
        }
        myClientSet.add(clt.clientCode);
      });
    }
    let timingErr = false;

    if (sTimings.length > 0) {
      availableDays = sTimings.map((timing) => {
        if (
          timing.available &&
          (timing.startTime === "" || timing.endTime === "")
        ) {
          timingErr = true;
        }
        if (
          timing.available &&
          (timing.startTime === "00:00" || timing.endTime === "00:00")
        ) {
          timingErr = true;
        }
        return {
          available: timing.available,
          startTime: timing.available ? stringToIntTime(timing.startTime) : 0,
          endTime: timing.available ? stringToIntTime(timing.endTime) : 0,
        };
      });
    }
    if (timingErr) {
      EventService.emit("showError", {
        message:
          "Please enter a valid start time and end time for the days marked available",
      });
      return;
    }
    if (sname.length === 0) {
      EventService.emit("showError", {
        message: "Please enter a valid name.",
      });
      return;
    }
    if (sDesc.length === 0) {
      EventService.emit("showError", {
        message: "Please enter a valid description.",
      });
      return;
    }

    if (breakStart === "") {
      EventService.emit("showError", {
        message: "Please select a valid break start time.",
      });
      return;
    }
    if (breakEnd === "") {
      EventService.emit("showError", {
        message: "Please select a valid break end time.",
      });
      return;
    }
    if (fileURL === null) {
      EventService.emit("showError", {
        message: "Please select a valid cover image",
      });
      return;
    }
    let bookingRegex = /^[0-9]{1,2}$/;
    if (forFamily && !bookingRegex.test(familyMembersCount)) {
      EventService.emit("showError", {
        message: "Please enter a valid family members allowed.",
      });
      return;
    }
    let fmCount = parseInt(familyMembersCount);
    if (fmCount < 0 || fmCount > 10) {
      EventService.emit("showError", {
        message:
          "Please enter a valid family members allowed(between 0 to 10).",
      });
      return;
    }
    let priceRegex = /^[0-9]{1,4}$/;
    if (!priceRegex.test(price)) {
      EventService.emit("showError", {
        message: "Please enter a valid price value.",
      });
      return;
    }
    if (!priceRegex.test(discount)) {
      EventService.emit("showError", {
        message: "Please enter a valid discount value.",
      });
      return;
    }
    if (parseFloat(discount) > parseFloat(price)) {
      EventService.emit("showError", {
        message: "Discounted price cannot be more than price.",
      });
      return;
    }

    if (!props.isCustom) {
      let numPattern = /^\d{1,}$/;
      if (clientArr && clientArr.length === 0) {
        EventService.emit("showError", {
          message: "Please add at least 1 client.",
        });
        return;
      }
      if (numPattern.test(sDuration) === false) {
        EventService.emit("showError", {
          message: "Please enter a valid duration.",
        });
        return;
      }

      if (numPattern.test(sUnit) === false) {
        EventService.emit("showError", {
          message: "Please enter a valid maximum slots.",
        });
        return;
      }

      if (!numPattern.test(bookingNoticeMin)) {
        EventService.emit("showError", {
          message: "Please enter a valid min booking notice.",
        });
        return;
      }
      if (!numPattern.test(bookingNoticeMax)) {
        EventService.emit("showError", {
          message: "Please enter a valid max booking notice.",
        });
        return;
      }
      if (parseInt(bookingNoticeMin) < 0 || parseInt(bookingNoticeMin) > 365) {
        EventService.emit("showError", {
          message: "Please enter a valid min booking notice between 0 to 365.",
        });
        return;
      }
      if (parseInt(bookingNoticeMax) < 0 || parseInt(bookingNoticeMax) > 365) {
        EventService.emit("showError", {
          message: "Please enter a valid max booking notice between 0 to 365.",
        });
        return;
      }
      if (parseInt(bookingNoticeMax) <= parseInt(bookingNoticeMin)) {
        EventService.emit("showError", {
          message:
            "Max booking notice must be greater than min booking notice.",
        });
        return;
      }

      if (forFamily && !numPattern.test(familyMembersCount)) {
        EventService.emit("showError", {
          message: "Please enter a valid family members allowed.",
        });
        return;
      }
      let fmCount = parseInt(familyMembersCount);
      if (fmCount < 0 || fmCount > 10) {
        EventService.emit("showError", {
          message:
            "Please enter a valid family members allowed(between 0 to 10).",
        });
        return;
      }
    }

    let temp_method = draftToHtml(
      convertToRaw(instructions.getCurrentContent())
    );

    let body = {
      name: sname,
      description: sDesc,

      breakStart: stringToIntTime(breakStart),
      breakEnd: stringToIntTime(breakEnd),

      availableDays: JSON.stringify(availableDays),

      image: fileURL,

      availableForFamily: forFamily,

      familyMemberCount: forFamily ? fmCount : 0,

      price: parseInt(price),
      discountedPrice: parseInt(discount),
      unlimitedBookings: isUnlimitedService,
    };
    if (!props.isCustom) {
      body = {
        ...body,
        instructions: temp_method,
        duration: parseInt(sDuration),
        calendars: sCalendar.map((cld) => {
          return { email: cld };
        }),
        clients: clientArr,
        unitsPerSlot: parseInt(sUnit),
        minDayBooking: parseInt(bookingNoticeMin),
        maxDayBooking: parseInt(bookingNoticeMax),
        addressRequired: labtest ? true : addReq,
        isLabTest: labtest,
        packageId: packageId?.ID,
        type: serviceType?.name,
      };
    }

    setISSaving(true);

    if (!isPost && selectedServiceId) {
      navigoAPIServices.updateService(
        props.isCustom,
        props.cc,
        { ...body, ID: selectedServiceId },
        (res) => {
          setISSaving(false);
          if (res) {
            EventService.emit("showError", {
              message: "Service updated successfully",
            });

            globalTimer.current = setTimeout(() => {
              if (globalTimer.current) {
                clearTimeout(globalTimer.current);
                props.handleCloseSave(res);
              }
            }, 2000);
          } else {
            EventService.emit("showError", {
              message: "Couldn't update service.Try later",
            });
          }
        },
        (err) => {
          setISSaving(false);
          EventService.emit("showError", {
            message: "Couldn't update service.Try later",
          });
        }
      );
    } else {
      navigoAPIServices.createService(
        props.isCustom,
        props.cc,
        props.id,
        body,
        (res) => {
          setISSaving(false);
          if (res) {
            EventService.emit("showError", {
              message: "Service created successfully.",
            });

            globalTimer.current = setTimeout(() => {
              if (globalTimer.current) {
                clearTimeout(globalTimer.current);
                props.handleCloseSave(res);
              }
            }, 2000);
          } else {
            EventService.emit("showError", {
              message: "Couldn't create service.Try later",
            });
          }
        },
        (err) => {
          setISSaving(false);
          EventService.emit("showError", {
            message: "Couldn't create service.Try later",
          });
        }
      );
    }
  };

  const handleOpenService = (currService) => {
    // let currService = services.find((service) => service.ID == id);

    let timingArr = [];
    try {
      let availableDays = JSON.parse(currService.availableDays);

      if (availableDays.length > 0) {
        timingArr = navigoWeekDays.map((day, index) => {
          return {
            name: day,
            id: index,
            available: availableDays[index].available,
            endTime: GetStringSlot(availableDays[index].endTime),
            startTime: GetStringSlot(availableDays[index].startTime),
          };
        });
      }
    } catch (err) {}

    let allClients = [];
    if (currService.clients && currService.clients.length > 0) {
      allClients = currService.clients.map((cl) => {
        return { ...cl };
      });
    }

    let calendarArr = [];
    if (currService.calendars && currService.calendars.length > 0) {
      calendarArr = currService.calendars.map((cld) => {
        return `${cld.email}`;
      });
    }

    let instruct = editorHelperMethod(
      currService.instructions ? currService.instructions : ""
    );

    setSName(currService.name);
    setSDesc(currService.description);
    setInstructions(instruct);
    setSDuration(currService.duration);
    setBreakStart(GetStringSlot(currService.breakStart));
    setBreakEnd(GetStringSlot(currService.breakEnd));
    setSClients([...allClients]);
    setSUnit(currService.unitsPerSlot);
    setSTimings([...timingArr]);
    setSCalendar([...calendarArr]);
    setFileURL(currService.image ? currService.image : null);
    setAddressRequired(currService.addressRequired);
    setForFamily(currService.availableForFamily);
    setIsUnlimitedService(currService?.unlimitedBookings);
    setSelectedServiceId(currService.ID);
    setBookingNoticeMin(currService.minDayBooking);
    setBookingNoticeMax(currService.maxDayBooking);
    setFamilyMembersCount(
      currService.familyMemberCount ? currService.familyMemberCount : ""
    );
    setLabtest(currService.isLabTest);
    setIsView(props.isView ? true : false);
    setPrice(currService.price);
    setDiscount(currService.discountedPrice);

    setPackageId(
      props.packages?.find((ele) => ele.ID === currService.packageId)
    );
    setServiceType(serviceTypeArr.find((ele) => ele.name === currService.type));
    setLayout(3);
  };
  const getCalendarOptions = (v) => {};
  const handleAddNewClient = () => {
    let newClient = {
      clientCode: "",
      maxSlots: 1,
      serviceID: selectedServiceId,
      ID: "" + new Date().getTime() + Math.random(),
      price: "",
      discount: "",
    };
    let newClientList = [{ ...newClient }, ...sClients];
    // sClients.forEach((clt) => {
    //   newClientList.push({ ...clt });
    // });
    setSClients([...newClientList]);
  };
  const getClientCodeInfo = (v, index) => {
    let newClientCodeList = sClients.map((clt, ind) => {
      if (ind == index) {
        return { ...clt, clientCode: v.clientCode };
      }
      return { ...clt };
    });
    setSClients(newClientCodeList);
  };

  const handleCalendarOptionChange = (v) => {
    setSCalendar(v);
  };

  const handleAddNewService = () => {
    let timingArr = navigoWeekDays.map((day, index) => {
      return {
        name: day,
        id: index,
        available: false,
        endTime: "",
        startTime: "",
      };
    });
    setSName("");
    setSDesc("");
    setSDuration("");
    setBreakStart("");
    setBreakEnd("");
    setSClients([
      {
        clientCode: "",
        maxSlots: 1,
        ID: "" + new Date().getTime() + Math.random(),
        price: "",
        discount: "",
      },
    ]);
    setSUnit("");
    setSTimings([...timingArr]);
    setSCalendar([]);
    setSelectedServiceId(null);
    setFileURL(null);
    setInstructions(EditorState.createEmpty());
    setBookingNoticeMin(1);
    setBookingNoticeMax(7);
    setForFamily(false);
    setFamilyMembersCount("");
    setLabtest(false);
    setIsPost(true);
    setLayout(3);
    setServiceType("");
    setPackageId("");
  };
  const stringToIntTime = (s) => {
    let tt = s.split(":");
    let h = parseInt(tt[0]);
    let m = parseInt(tt[1]);
    return parseInt(h * 60 + m);
  };
  const handleDescChange = (e) => {
    setSDesc(e.target.value);
  };

  const handleCalendarAccessLink = () => {
    window.open(navigoAPIServices.getCalendarAccessLink(), "_blank");
  };

  const handleUploadFileClick = () => {
    if (uploadFileRef && uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };
  const handleUploadFile = (e) => {
    if (e.target.files[0].size > 1000000) {
      EventService.emit("showError", {
        message: "File size cannot be more than 1MB",
      });
      return;
    }
    handleFileUpload(
      e,
      (res) => {
        setFileURL(res.publicUrl);
      },
      (err) => {
        EventService.emit("showError", {
          message: "Something went wrong while uploading File.",
        });
      }
    );
  };
  const handleFileUpload = (e, onSuccess, onError) => {
    const data = new FormData();
    data.append("file", e.target.files[0], "filename");
    navigoAPIServices.uploadImg(
      data,
      (res) => {
        onSuccess(res);
      },
      (err) => {
        onError();
        console.log("error in uploading img", err);
      }
    );
  };

  const handleAddClientProperties = (property, client, value) => {
    setSClients((oldClientList) => {
      let newClientList = oldClientList.map((clt) => {
        if (clt.ID === client.ID) {
          return { ...clt, [property]: value };
        }
        return { ...clt };
      });
      return newClientList;
    });
  };

  return (
    <div className="navigo-services-layout-container">
      {layout === 1 && (
        <div className="navigo-services-global-skeleton-loader">
          {" "}
          <GlobalSkeletonLoader />
        </div>
      )}
      {layout === 2 && <NoServiceFoundUI />}
      {layout === 3 && (
        <div className="navigo-services-layout-2-container">
          <div className="navigo-services-layout-2-section-1">
            <input
              type="text"
              value={sname}
              disabled={isView}
              onChange={(e) => {
                setSName(e.target.value);
              }}
              placeholder="Name"
              className="navigo-services-layout-2-section-1-name"
            />
            {/* <h1>{sname}</h1> */}
            {!isView && (
              <div className="navigo-services-layout-2-section-1-right">
                <button id="save" onClick={handleSave} disabled={isSaving}>
                  Save
                </button>
                <button
                  id="cancel"
                  onClick={() => {
                    props.handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <div className="navigo-services-layout-2-section-2">
            <p>Description</p>

            <TextareaAutosize
              minRows={6}
              maxRows={6}
              aria-label="service-description-text-are"
              placeholder="Description"
              value={sDesc}
              onChange={handleDescChange}
              disabled={isView}
              className="navigo-services-layout-2-section-2-description"
              // style={{ height: 200, width: 600 }}
            />
          </div>
          {!props.isCustom && (
            <div className="navigo-services-layout-2-section-instruction">
              <p className="navigo-services-layout-2-section-instruction-heading ">
                Instructions
              </p>
              <div>
                <Editor
                  options={[
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                  ]}
                  toolbar={{
                    options: ["inline", "list", "history", "remove"],
                    inline: {
                      options: [
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "monospace",
                      ],
                    },
                    list: {
                      options: ["unordered", "ordered"],
                    },
                  }}
                  toolbarClassName={"navigo-toolbar-class "}
                  // toolbarClassName={showToolbar ? null : " toolBar-hidden-pn"}
                  editorState={instructions}
                  wrapperClassName="navigo-service-sd-wrapper-editor-wrapper-class"
                  editorClassName="navigo-service-sd-editor-wrapper-editor-class"
                  onEditorStateChange={(e) => {
                    setInstructions(e);
                  }}
                  placeholder="   Type here ..."
                  disabled={isView}
                />
              </div>
            </div>
          )}
          <div className="navigo-services-layout-2-section-10">
            <p>Cover</p>

            {fileURL !== null && (
              <div className="navigo-services-layout-2-section-10-layout-2">
                <img src={fileURL} />
              </div>
            )}

            <div className="navigo-services-layout-2-section-10-layout-1">
              <button onClick={handleUploadFileClick} disabled={isView}>
                choose an image
              </button>
              <input
                type="file"
                hidden
                accept={"image/*"}
                ref={uploadFileRef}
                onChange={handleUploadFile}
              />
            </div>
          </div>
          {!props.isCustom && (
            <div className="navigo-services-booking-notice-module navigo-service-type-package">
              <div className="navigo-services-layout-2-section-4-5">
                <p>Service Type</p>
                <Autocomplete
                  value={serviceType}
                  onChange={(e, v) => {
                    if (v) {
                      setServiceType(v);
                    }
                  }}
                  disabled={isView}
                  options={serviceTypeArr}
                  noOptionsText={""}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select service type"
                      className="autocomplete-text-field-class"
                    />
                  )}
                />
              </div>
              <div className="navigo-services-layout-2-section-4-5">
                <p>Package Id (Optional)</p>
                <Autocomplete
                  value={packageId}
                  onChange={(e, v) => {
                    if (v) {
                      setPackageId(v);
                    }
                  }}
                  disabled={isView}
                  options={props.packages}
                  noOptionsText={""}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Package name"
                      className="autocomplete-text-field-class"
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="navigo-services-layout-2-section-3">
            <div className="navigo-services-layout-2-section-3-top">
              <p>Available days</p>
            </div>
            <div className="navigo-services-layout-2-section-3-bottom">
              {sTimings.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className="navigo-services-timing-container"
                  >
                    <div className="navigo-services-timing-top">
                      <input
                        type="checkbox"
                        checked={item.available}
                        disabled={isView}
                        onChange={(e) => {
                          let newSTimingArr = sTimings.map((ele, ind) => {
                            if (ind == index) {
                              return { ...ele, available: e.target.checked };
                            }
                            return { ...ele };
                          });
                          setSTimings(newSTimingArr);
                        }}
                      />
                      <p>{item.name}</p>
                    </div>
                    <div className="navigo-services-timing-mid-bottom">
                      <p>Start Time</p>
                      <Select
                        value={item.startTime}
                        disabled={!item.available || isView}
                        onChange={(e) => {
                          let newSTimingArr = sTimings.map((ele, ind) => {
                            if (ind == index) {
                              return { ...ele, startTime: e.target.value };
                            }
                            return { ...ele };
                          });
                          setSTimings(newSTimingArr);
                        }}
                        className="navigo-dashboard-select-css navigo-services-timing-mid-bottom-startTime-endTime"
                      >
                        {NavigoTimeArr.map((time) => {
                          return (
                            <MenuItem value={time.time}>{time.time}</MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="navigo-services-timing-mid-bottom">
                      <p>End Time</p>
                      <Select
                        value={item.endTime}
                        disabled={!item.available || isView}
                        onChange={(e) => {
                          let newSTimingArr = sTimings.map((ele, ind) => {
                            if (ind == index) {
                              return { ...ele, endTime: e.target.value };
                            }
                            return { ...ele };
                          });
                          setSTimings(newSTimingArr);
                        }}
                        className="navigo-services-timing-mid-bottom-startTime-endTime navigo-dashboard-select-css"
                      >
                        {NavigoTimeArr.map((time) => {
                          return (
                            <MenuItem
                              value={time.time}
                              disabled={time.time <= item.startTime}
                            >
                              {time.time}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {!props.isCustom && (
            <div className="navigo-services-layout-2-section-4-5">
              <p>{`Duration(mins)`}</p>
              <input
                type="text"
                value={sDuration}
                disabled={isView}
                onChange={(e) => {
                  setSDuration(e.target.value);
                }}
              />
            </div>
          )}
          {!props.isCustom && (
            <div className="navigo-services-booking-notice-module">
              <div className="navigo-services-layout-2-section-4-5">
                <p>Min booking notice (days)</p>
                <input
                  type="number"
                  value={bookingNoticeMin}
                  disabled={isView}
                  onChange={(e) => {
                    setBookingNoticeMin(e.target.value);
                  }}
                />
              </div>
              <div className="navigo-services-layout-2-section-4-5">
                <p>Max advance booking (days)</p>
                <input
                  type="number"
                  value={bookingNoticeMax}
                  disabled={isView}
                  onChange={(e) => {
                    setBookingNoticeMax(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          {!props.isCustom && (
            <div className="navigo-services-layout-2-section-4-5">
              <p>units per slot</p>
              <input
                type="number"
                value={sUnit}
                disabled={isView}
                onChange={(e) => {
                  setSUnit(e.target.value);
                }}
              />
            </div>
          )}
          <div className="navigo-services-layout-2-section-6-8">
            <p>Break</p>
            <div className="navigo-services-layout-2-section-6-8-top-bottom">
              <p>Break start</p>
              <p>Break end</p>
            </div>
            <div className="navigo-services-layout-2-section-6-8-top-bottom">
              <Select
                value={breakStart}
                disabled={isView}
                onChange={(e) => {
                  setBreakStart(e.target.value);
                }}
                className="navigo-services-timing-mid-bottom-startTime-endTime navigo-dashboard-select-css"
              >
                {NavigoTimeArr.map((time) => {
                  return <MenuItem value={time.time}>{time.time}</MenuItem>;
                })}
              </Select>
              <Select
                value={breakEnd}
                disabled={isView}
                onChange={(e) => {
                  setBreakEnd(e.target.value);
                }}
                className="navigo-services-timing-mid-bottom-startTime-endTime navigo-dashboard-select-css"
              >
                {NavigoTimeArr.map((time, ind) => {
                  return (
                    <MenuItem
                      value={time.time}
                      disabled={time.time <= breakStart}
                    >
                      {time.time}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="navigo-services-booking-price-module">
            <div className="navigo-services-layout-2-section-4-5">
              <p>Price</p>
              <input
                type="number"
                value={price}
                disabled={isView}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
            </div>
            <div className="navigo-services-layout-2-section-4-5">
              <p>Discounted price</p>
              <input
                type="number"
                value={discount}
                disabled={isView}
                onChange={(e) => {
                  setDiscount(e.target.value);
                }}
              />
            </div>
          </div>
          {!props.isCustom && (
            <div className="navigo-services-layout-2-section-calendar">
              <div className="navigo-services-layout-2-section-calendar-top">
                <p>Calendars</p>
                <button onClick={handleCalendarAccessLink} disabled={isView}>
                  + Add & access to your calendar
                </button>
              </div>

              <Autocomplete
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  maxWidth: "600px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                multiple
                id="multiple-calendar-tags"
                options={calendarList}
                noOptionsText={""}
                getOptionLabel={(option) => {
                  return option;
                }}
                value={sCalendar}
                onOpen={() => {
                  handleGetAllCalendars();
                }}
                onChange={(e, v) => {
                  if (v) {
                    handleCalendarOptionChange(v);
                  }
                }}
                disabled={isView}
                renderInput={(params) => (
                  <TextField
                    onChange={(e, v) => {
                      getCalendarOptions(e.target.value);
                    }}
                    {...params}
                    placeholder=""
                    className="autocomplete-text-field-class"
                  />
                )}
              />
            </div>
          )}
          {!props.isCustom && (
            <div className="navigo-services-layout-2-section-6-8">
              <div className="navigo-services-layout-2-section-6-8-top-bottom-client">
                <div className="navigo-services-layout-2-section-6-8-top-bottom-div">
                  <p>Clients</p>

                  <button
                    className="navigo-services-layout-2-section-6-8-button"
                    onClick={handleAddNewClient}
                    disabled={isView}
                  >
                    + Add new
                  </button>
                </div>

                <p>Max slots</p>
              </div>
              <div className="navigo-services-layout-2-section-client-container">
                {sClients &&
                  sClients.map((client, index) => {
                    return (
                      <div
                        className="navigo-services-layout-2-section-6-8-top-bottom-client"
                        key={client.ID}
                      >
                        <Autocomplete
                          id="search-patient-bar"
                          className="navigo-services-layout-2-section-6-8-top-bottom-client-no-border"
                          options={clientCodeList}
                          freeSolo
                          value={client}
                          disabled={isView}
                          onChange={(e, v) => {
                            if (v) {
                              getClientCodeInfo(v, index);
                            }
                          }}
                          getOptionLabel={(option) => {
                            return option.clientName
                              ? option.clientName + ` (${option.clientCode})`
                              : option.clientCode;
                          }}
                          renderInput={(params) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #d8d8d8",
                              }}
                            >
                              <TextField
                                className="navigo-services-layout-2-section-6-8-top-bottom-autocomplete"
                                {...params}
                                placeholder="client code"
                              />

                              <img
                                src={editPencilIconOrange}
                                className="navigo-dashboard-cursor-pointer-class navigo-dashboard-client-edit-icon"
                                onClick={() => {
                                  history.push(
                                    `/miscellaneous/whitelisting?cc=${client.clientCode}`
                                  );
                                }}
                              />
                            </div>
                          )}
                        />
                        {/* <Button
                        className="navigo-service-client-close-button"
                        onClick={() => {
                          window.open(
                            `/miscellaneous/whitelisting?cc=${client.clientCode}`,
                            "_self"
                          );
                        }}
                      >
                        <img src={editPencilIcon} />
                      </Button> */}

                        <input
                          type="number"
                          value={client.maxSlots}
                          disabled={isView}
                          onChange={(e) => {
                            handleAddClientProperties(
                              "maxSlots",
                              client,
                              e.target.value
                            );
                          }}
                        />

                        <Button
                          className="navigo-service-client-close-button"
                          disabled={isView}
                          onClick={() => {
                            let newClientCodeList = sClients.filter(
                              (myCli) => myCli.ID !== client.ID
                            );
                            setSClients(newClientCodeList);
                          }}
                        >
                          <FontIcon className="navigo-services-client-code-icon">
                            close
                          </FontIcon>
                        </Button>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          <div className="navigo-services-layout-2-section-9">
            {/* <p className="navigo-services-layout-2-section-9-heading">
              Filters
            </p> */}
            <div className="navigo-services-layout-2-section-9-top">
              <div className="navigo-services-layout-2-section-9-top-group">
                <div className="navigo-services-layout-2-section-9-item">
                  <input
                    type="checkbox"
                    checked={isUnlimitedService}
                    disabled={isView}
                    onChange={(e) => {
                      setIsUnlimitedService(e.target.checked);
                    }}
                  />
                  <p>Is unlimited service?</p>
                </div>
                <div className="navigo-services-layout-2-section-9-item">
                  <input
                    type="checkbox"
                    checked={forFamily}
                    disabled={isView}
                    onChange={(e) => {
                      setForFamily(e.target.checked);
                    }}
                  />
                  <p>Can be availed for family?</p>
                </div>
                {forFamily && (
                  <div className="navigo-services-layout-2-section-9-right navigo-services-layout-2-section-9-item">
                    <p>Number of family members allowed:</p>
                    <input
                      type="number"
                      max={10}
                      maxLength={2}
                      disabled={isView}
                      value={familyMembersCount}
                      onChange={(e) => {
                        if (e.target.value.length <= 2) {
                          setFamilyMembersCount(e.target.value);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {!props.isCustom && (
              <div className="navigo-services-layout-2-section-9-item">
                <input
                  type="checkbox"
                  checked={addReq}
                  disabled={isView}
                  onChange={(e) => {
                    setAddressRequired(e.target.checked);
                  }}
                />
                <p>Address required</p>
              </div>
            )}
            {!props.isCustom && (
              <div className="navigo-services-layout-2-section-9-item">
                <input
                  type="checkbox"
                  checked={labtest}
                  disabled={true}
                  onChange={(e) => {
                    setLabtest(e.target.checked);
                  }}
                />
                <p style={{ color: "#d8d8d8" }}>isLabtest</p>
              </div>
            )}
            {/* <div className="navigo-services-layout-2-section-9-item">
              <input type="checkbox" checked={false} />
              <p>designation</p>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

function NoServiceFoundUI(props) {
  return (
    <div className="no-service-found-ui">
      <img src={noInsight} />
      <p>
        No such service
        <br /> customisation found.
      </p>
    </div>
  );
}

export default withRouter(NavigoSingleService);
