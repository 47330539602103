import React, { useState, useEffect } from "react";
import { Select, MenuItem, Radio } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EnvironmentService from "service/environment/EnvironmentService";
import SendDocumentService from "../services/SendDocumentService";
import {
  ReturnHtmlDemo,
  ReturnHtmlDemoCallNotes,
} from "../constants/HealthplanTemplate";
import DocumentService from "../../documents/services/DocumentService";
import { Button } from "react-md";
import { TextField } from "@material-ui/core";
import Validator from "util/Validator";
import "../style/index.css";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Success, Error } from "../components/EmailSuccess";
import { checkCorporate } from "../../../../../../../constants/CorporateClients";

const environmentService = new EnvironmentService();
const SMS_BODY =
  "Hi {0}, Hope you are satisfied with your health checkup by Zyla. Your feedback will help improve our services {1}";
// const HP_FEEDBACK_LINK =
//   "https://api.simplesat.io/api/rating/kEXX9Ig4dHUBrbFkScJJXPh1fhqwzJENBXs60bQ79B8/?source=generic_embed";
const emailEventList = [
  { value: "252162529", name: "Document Template", screen: "two" },
  { value: "252165020", name: "Call Note", screen: "callNote" },
];
const selectBody = {
  HEALTH_PLAN_AND_RECIPE:
    " As per your discussion with our care team, we have personalized a detailed health plan for you, with delicious recipes, that is attached with this email.",
  DETAILED_ASSESMENT:
    " As per your discussion with our care team, we have personalized a detailed health assessment for you that is attached to this email.",
  HEALTH_PLAN_AND_EXERCISE:
    " As per your discussion with our care team, we have personalized a Home Exercise Plan for you that is attached to this email.",
  LABTEST:
    " As your trusted healthcare expert, we are sharing the report of your full body health checkup attached to this email. You can find your detailed report analysis on the Zyla App within 24 hours.",
  AZLABTEST:
    " As your trusted healthcare expert, we are sharing the report of your basic health checkup attached to this email. You can find your detailed report analysis on the Activ health app within 24 hours.",
  HEALTH_PLAN_AND_NUTRITION:
    " As per your discussion with our care team, we have personalized nutrition guidelines for you that are attached to this email.",
  AHC_REPORT_DOC:
    "As your trusted healthcare expert, we are sharing the lab test report of your full body health checkup attached to this email.<br/><br/>Please schedule an appointment to consult your test reports with doctor -<a href='http://doctor-consultation.youcanbook.me'> Book here </a>",
};
const selectMenuItems = [
  {
    name: "DETAILED ASSESMENT",
    value: "DETAILED_ASSESMENT",
    mailTemplate: "252193821",
    emailSubject: "{0}, your health assessment reports are available",
    sendNPS: false,
    eventName: "REPORT_DA",
    feedbackLink: "",
  },
  {
    name: "HEALTH PLAN AND RECIPE",
    value: "HEALTH_PLAN_AND_RECIPE",
    mailTemplate: "252193821",
    emailSubject: "{0}, your health assessment reports are available",
    sendNPS: false,
    eventName: "REPORT_HP_RECIPE",
    feedbackLink: "",
  },
  {
    name: "HEALTH PLAN AND NUTRITION",
    value: "HEALTH_PLAN_AND_NUTRITION",
    mailTemplate: "252193821",
    emailSubject: "{0}, your health assessment reports are available",
    sendNPS: false,
    eventName: "REPORT_HP_NUTRITION",
    feedbackLink: "",
  },
  {
    name: "HEALTH PLAN AND EXERCISE",
    value: "HEALTH_PLAN_AND_EXERCISE",
    mailTemplate: "252193821",
    emailSubject: "{0}, your health assessment reports are available",
    sendNPS: false,
    eventName: "REPORT_HP_EXERCISE",
    feedbackLink: "",
  },
  {
    name: "LAB TEST",
    value: "LABTEST",
    mailTemplate: "252193742",
    sendNPS: false,
    eventName: "REPORT",
    feedbackLink: "",
  },
  {
    name: "Abhi Lab Test",
    value: "AZLABTEST",
    mailTemplate: "252193742",
    emailSubject: "{0}, your health checkup reports are available",
    sendNPS: true,
    eventName: "REPORT",
    feedbackLink:
      "https://api.simplesat.io/api/rating/ID4dyxat68mXXv89ToHry8FHuctB8zFZ5IyyiUv0ZAw/?source=generic_embed",
  },
  {
    name: "AHC REPORT WITH DOC",
    value: "AHC_REPORT_DOC",
    mailTemplate: "252193742",
    emailSubject: "{0}, your health checkup reports are available",
    sendNPS: true,
    eventName: "REPORT",
    feedbackLink:
      "https://api.simplesat.io/api/rating/kEXX9Ig4dHUBrbFkScJJXPh1fhqwzJENBXs60bQ79B8/?source=generic_embed",
  },
];
const KESNIO_SYS_ID = "cepapi0063";

function SendEmailComponent(props) {
  const documentService = new DocumentService();
  const sendDocumentService = new SendDocumentService();
  useEffect(() => {}, []);

  const validator = new Validator();
  const [screen, setScreen] = useState("one"); //one
  const [secondaryScreen, setSecondaryScreen] = useState(""); //one
  const [emailTemplateId, setEmailTemplateId] = useState("");

  const [documentError, setDocumentError] = useState(false);
  const [disableButton, setDisable] = useState(false);
  const [sendNpsSms, setNpsSms] = useState(false);
  const [emailType, setEmailType] = useState("DETAILED_ASSESMENT");
  const [eventName, setEventName] = useState("CALL_NOTE");
  const [emailBody, setEmailBody] = useState(selectBody["DETAILED_ASSESMENT"]);
  const [emailSubject, setEmailSubject] = useState("");
  const [feedbackLink, setFeedbackLink] = useState("");
  const [email, setEmail] = useState(
    props.patient.email !== undefined ? props.patient.email : ""
  );

  const [clientCode, setclientCode] = useState(
    props.patient.clientCode !== undefined ? props.patient.clientCode : ""
  );
  const [phoneNo, setPhoneNo] = useState(
    props.patient.phoneno !== undefined ? props.patient.phoneno : ""
  );
  const [emailError, setEmailError] = useState(false);

  const [name, setName] = useState(
    props.patient.firstName !== undefined ? props.patient.firstName : ""
  );
  const [nameError, setNameError] = useState(false);

  let editorState = EditorState.createEmpty();
  let description = `Practice the guidelines as mentioned below to reduce body weight:

  1. Hydration: Hydration is important to detoxify your body and remove waste material
  Drink 2.5 to 3 litres of water throughout the day
  Keep yourself hydrated with lemon water, coconut water and kokum sharbat

  2. Fibre intake: Increase fibre intake to initiate weight loss
  Eat 2-3 fruits in your mid meals. Have 1 fruit between your breakfast and lunch and another between lunch and dinner
  Add a small plate of salads to your lunch and dinner

  3. Herbal tea: Drink a cup of peppermint tea 2-3 times in a day. Boil 4-5 mint leaves, 1 pinch black pepper, 1 inch ginger in 1 cup of water for 2-3 minutes. Strain water and drink.
  
  4. Protein: Protein rich food help in improving muscle mass and reducing fat mass.
  Add a glass of milk to your breakfast
  Have a small bowl of dal in your lunch
  Enjoy a protein rich meal like raw paneer (50 grams) or sprouts or chana chat in the evening

  5. Fats: Add omega 3 fatty acid rich food in your mid meals to reduce belly fat
  Have a handful of nuts (almonds, walnuts, cashews, peanuts) and seeds (flax, pumpkin, sunflower, chia and sabja seeds) in your mid meals
  Start your day with overnight soaked almonds (6) and walnuts (2)

  6. Physical activity: physical activity is important to burn the extra calories
  Involve yourself in 45 minutes of physical activity 5-6 days a week
  Increase the duration and intensity of activity gradually
  `;

  let contentBlock = htmlToDraft(
    props.emailMessage !== undefined && props.emailMessage.length > 0
      ? props.emailMessage
      : description
  );
  if (contentBlock) {
    let contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    editorState = EditorState.createWithContent(contentState);
  }

  const [standardDesc, setStandardDesc] = useState(editorState);
  // patient={patient}
  // document={document}
  // closeDialog={() => {
  //   this.setState({ sendDialogContainer: "" });
  // }}
  const [document, setDocument] = useState(props.document);

  // setEmailError(true)
  const onHandleSelectChange = (e) => {
    setEmailType(e.target.value);

    setEmailBody(selectBody[e.target.value]);

    let selectedItem = selectMenuItems.find(
      (itm) => itm.value === e.target.value
    );
    if (selectedItem !== undefined) {
      setEventName(selectedItem.eventName);
      setEmailTemplateId(selectedItem.mailTemplate);
      setEmailSubject(selectedItem.emailSubject.format(name));
      setNpsSms(selectedItem.sendNPS);
      setFeedbackLink(selectedItem.feedbackLink);
    }
  };

  const sendEmail = (emailBody) => {
    let attachment = document.publicUrl;
    console.log("attachment is:", document.publicUrl);

    let requestBody = {
      sysid: KESNIO_SYS_ID,
      emailid: email,
      msgid: emailTemplateId,
      attributes: {
        "user.FirstName": name,
        "user.CustomAttribute.bookingStart": "",
        "user.CustomAttribute.bookingDuration": "",
        "user.CustomAttribute.bookingReference": emailBody,
        "user.CustomAttribute.cancelLink": "",
        "user.CustomAttribute.rescheduleLink": attachment, //download link
        "user.CustomAttribute.bookingPhone": props.patient.phoneno.toString(),
        "user.CustomAttribute.emailSubject": emailSubject,
      },
    };
    console.log("reached before onResponse")
    let onResponse = () => {
      setScreen("success");
      auditEmail();
      shortUrl();

      if (
        sendNpsSms &&
        environmentService.isProduction() &&
        (checkCorporate(clientCode) || clientCode === "AB")
      ) {
        shortUrlForNps();
      }
    };
    let onError = (error) => {
      setSecondaryScreen(screen);
      setScreen("error");
      setDisable(false);
    };

    documentService.sendEmail(requestBody, onResponse, onError);
  };

  const shortUrlForNps = () => {
    let hp_feedback =
      feedbackLink +
      "&customer_email=" +
      email +
      "&customer_first_name=" +
      name +
      "&company_name=" +
      phoneNo;

    let requestBody = {
      originalURL: hp_feedback,
    };

    let onResponse = (response) => {
      sendFeedback(response.secureShortURL);
    };
    let onError = (err) => {};
    if (feedbackLink.length > 0)
      documentService.whatsappShort(requestBody, onResponse, onError);
  };
  const sendFeedback = (feedback) => {
    let smsBody = SMS_BODY.format(name, feedback);

    let requestBody = {
      body: smsBody,
      entityid: "1301158167847151139",
      phoneNo: phoneNo.toString(),
      senderId: "ZYLAHE",
      templateid: "1107167221967017946",
    };

    var onResponse = () => {
      this.helper.stopLoading();
    };
    var onError = () => {
      this.helper.stopLoading();
    };
    sendDocumentService.sendSms(requestBody, onResponse, onError);
  };

  const shortUrl = () => {
    // let requestBody = {
    //   originalURL: document.publicUrl,
    // };

    // Structure to send to sendWhatsapp function (response from backend):
    // type ShortUrlResponse struct{
    //   OriginalURL string `json:"originalURL"`
    //   Path string `json:"path"`
    //   SecureShortURL string `json:"secureShortURL"`
    //   ShortURL string `json:"shortURL"`
    // }

    let inputStrcuture = {
      originalURL: document.publicUrl,
      path: "",
      secureShortURL: document.publicUrl,
      shortURL: "",
    }

    console.log("input structure:", inputStrcuture);
    sendWhatsapp(inputStrcuture); // Send Whatsapp using original URL 


    // let onResponse = (response) => {
    //   if (response && response.path !== undefined) sendWhatsapp(response);
    // };
    // let onError = (error) => {};
    // if (document.publicUrl.length > 0)
    //   documentService.whatsappShort(requestBody, onResponse, onError);
  };

  const sendWhatsapp = (url) => {
    let requestBody = {
      userId: props.patient.id.toString(),
      phoneNumber: props.patient.phoneno.toString(),
      countryCode: props.patient.countryCode.toString(),
      event: eventName,
      traits: {
        // document_url: url.path !== "" ? url.path : "",
        document_url: url.originalURL,
        document_url_full: url.secureShortURL,
        // document_url_full: url.originalURL,
      },
    };
    let onResponse = () => {
      // props.closeDialog();
    };
    let onError = (error) => {
      // props.closeDialog();
    };

    documentService.whatsappEvent(requestBody, onResponse, onError);
  };

  const auditEmail = () => {
    let requestBody = {
      patientId: props.patient.id,
      email: email,
      type: emailType,
      name: document.fileName,
      link: document.publicUrl,
      createdAt: new Date().toISOString(),
      status: "sent",
      channel: "email",
    };
    let onResponse = () => {};
    let onError = (error) => {};

    documentService.audit(requestBody, onResponse, onError);
  };

  return (
    <div
      className="send-email-editor-container"
      style={{
        width: "100%",
        padding: "4px 20px 10px 20px",
        minWidth: "300px",
      }}
    >
      <div className="send-email-editor-top">
        {screen === "one" && (
          <Row className="full-width">
            <Col className="full-width">
              <Row>
                <label className="doucment-email-heading">
                  Patient Details:
                </label>
              </Row>
              <Row className="width-full-item-document-email">
                <label className="doucment-email-label">
                  Name{" "}
                  {nameError && (
                    <label
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}
                    >
                      Name required!
                    </label>
                  )}
                </label>

                <TextField
                  name="fr"
                  className="full-width"
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);
                    if (e.target.value.length === 0) {
                      setNameError(true);
                    }
                  }}
                  value={name !== undefined ? name : ""}
                ></TextField>
              </Row>

              <Row className="width-full-item-document-email ">
                <label className="doucment-email-label">
                  Email{" "}
                  {emailError && (
                    <label
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}
                    >
                      Email required!
                    </label>
                  )}
                </label>

                <TextField
                  name="fr"
                  type="text"
                  className="full-width"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                    if (e.target.value.length === 0) {
                      setEmailError(true);
                    }
                  }}
                  value={email !== undefined ? email : ""}
                ></TextField>
              </Row>
              {document.fileName && (
                <Row className="width-full-item-document-email email-doc-btn">
                  <label className="doucment-email-label">File attached</label>
                  {" " + document.fileName}
                </Row>
              )}

              <Row className="item-document-email-row">
                <Button
                  secondary
                  raised
                  onClick={() => {
                    props.closeEmail();
                  }}
                >
                  Back
                </Button>
                <Button
                  raised
                  primary
                  onClick={() => {
                    if (name) {
                      if (email) {
                        if (validator.validateEmail(email)) {
                          setScreen("emailType");

                          setEmailError(false);
                        } else {
                          setEmailError(true);
                        }
                      } else {
                        setEmailError(true);
                      }
                    } else {
                      setNameError(true);
                    }
                  }}
                >
                  Next
                </Button>
              </Row>
            </Col>
          </Row>
        )}
        {screen === "emailType" && (
          <Col>
            <Row style={{ paddingBottom: "10px" }}>
              <label className="doucment-email-heading">
                Select email template
              </label>
            </Row>

            {emailEventList.map((event, index) => (
              <Row
                className="document-email-item"
                key={event.value}
                onClick={() => {
                  setScreen(event.screen);
                  setEmailTemplateId(event.value);
                  setEmailType("CALL_NOTE");
                }}
              >
                <Radio checked={event.value === emailTemplateId} />{" "}
                <Button className="font-label-select">{event.name}</Button>
              </Row>
            ))}

            <Row className="item-document-email-row">
              <Button
                secondary
                raised
                onClick={() => {
                  setScreen("one");
                }}
              >
                Back
              </Button>
            </Row>
          </Col>
        )}
        {screen === "two" && (
          <div className="item-document-email-row full-width">
            <span className="doucment-email-label">
              {"Name: " + name}
              <br />
              {"Email: " + email}
            </span>

            <div className="send-email-editor-top-right">
              <span className="send-email-editor-top-right-span">Type:</span>
              <Select
                onChange={(e) => onHandleSelectChange(e)}
                value={emailType}
                className="send-email-editor-top-right-select"
              >
                {selectMenuItems.map((item) => {
                  return <MenuItem value={item.value}>{item.name}</MenuItem>;
                })}
              </Select>
            </div>
          </div>
        )}
      </div>
      {screen === "two" && (
        <div className="send-email-editor-box">
          <div
            dangerouslySetInnerHTML={{
              __html: ReturnHtmlDemo({
                name: name,
                emailBody: emailBody,
                document: document.publicUrl,
              }),
            }}
          ></div>
        </div>
      )}
      {documentError && (
        <Row
          center="xs"
          style={{ color: "red", paddingTop: "10px", paddingBottom: "10px" }}
        >
          Attach document to continue
        </Row>
      )}
      {screen === "two" && (
        <div className="send-email-editor-bottom">
          <Button
            secondary
            raised
            style={{
              marginRight: "10px",
            }}
            onClick={() => {
              setScreen("emailType");
            }}
          >
            Back
          </Button>

          <Button
            primary
            raised
            disabled={disableButton}
            style={{
              marginRight: "10px",
            }}
            onClick={() => {
              if (email) {
                if (validator.validateEmail(email)) {
                  if (
                    document &&
                    document.publicUrl !== undefined &&
                    document.publicUrl.length > 0
                  ) {
                    setDisable(true);
                    setEmailError(false);
                    setDocumentError(false);
                    sendEmail(emailBody);
                  } else {
                    setDocumentError(true);
                    setTimeout(() => {
                      setDocumentError(false);
                    }, 3000);
                  }
                } else {
                  setEmailError(true);
                }
              } else {
                setEmailError(true);
              }
            }}
          >
            Send
          </Button>
          {/* <Button
            secondary
            raised
            onClick={() => {
              props.closeDialog();
            }}
          >
            Cancel
          </Button> */}
        </div>
      )}
      {screen === "callNote" && (
        <span className="email-heading">Email Body:</span>
      )}
      {screen === "callNote" && (
        <div
          className="send-email-editor-box"
          style={{
            border: "3px solid black",
            borderRadius: "10px",
          }}
        >
          <Editor
            options={[
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
            ]}
            toolbar={{
              options: ["inline", "list", "history", "remove", "textAlign"],
              inline: {
                options: [
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "monospace",
                ],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            toolbarClassName={true ? null : "toolBar-hidden"}
            // toolbarClassName={showToolbar ? null : " toolBar-hidden-pn"}
            editorState={standardDesc}
            wrapperClassName="nutrition-sd-wrapper-editor-wrapper-class"
            editorClassName="nutrition-sd-editor-wrapper-editor-class"
            hashtag={{
              separator: " ",
              trigger: "#",
            }}
            onEditorStateChange={(e) => {
              setStandardDesc(e);
            }}
            placeholder="   Type here ..."
          />
        </div>
      )}
      {screen === "callNote" && (
        <div className="send-email-editor-box">
          <span className="email-heading">Email Preview:</span>
          <br />
          <span className="doucment-email-label">
            {"Name: " + name}
            <br />
            {"Email: " + email}
          </span>
          <div
            dangerouslySetInnerHTML={{
              __html: ReturnHtmlDemoCallNotes({
                name: name,
                emailBody: draftToHtml(
                  convertToRaw(standardDesc.getCurrentContent())
                ),
              }),
            }}
          ></div>
        </div>
      )}

      {screen === "callNote" && (
        <div className="send-email-editor-bottom">
          <Button
            secondary
            raised
            style={{
              marginRight: "10px",
            }}
            onClick={() => {
              setScreen("emailType");
            }}
          >
            Back
          </Button>
          <Button
            primary
            raised
            disabled={disableButton}
            style={{
              marginRight: "10px",
            }}
            onClick={() => {
              if (email) {
                if (validator.validateEmail(email)) {
                  setDisable(true);
                  sendEmail(
                    draftToHtml(convertToRaw(standardDesc.getCurrentContent()))
                  );
                  setEmailError(false);
                } else {
                  setEmailError(true);
                }
              } else {
                setEmailError(true);
              }
            }}
          >
            Send
          </Button>
        </div>
      )}
      {screen === "success" && (
        <Success backButton={() => props.closeEmail()} name={"Email"} />
      )}
      {screen === "error" && (
        <Error backButton={() => setScreen(secondaryScreen)} name={"Email"} />
      )}
    </div>
  );
}

export default SendEmailComponent;
